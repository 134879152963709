import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
//import { LoginComponent } from './components/login/login.component';
import { SharedModule } from '@shared/shared.module';
import { NavCustomComponent } from '@core/components/nav-custom/nav-custom.component';
import { NotFoundComponent } from '@core/components/not-found/not-found.component';
import { NewUrlNoticeComponent } from '@core/components/new-url-notice/new-url-notice.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { PreloaderComponent } from '@app/core/components/preloader/preloader.component';
import { SettingsService } from './shared/settings/settings.service';
import { ArchivesService } from './shared/archives/archives.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AysCommonsModule } from '@ays';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../environments/environment';
import { NavigationService } from './shared/navigation/navigation.service';
// import { ErrorComponent } from './shared/error/error.component';

@NgModule({
    imports: [SharedModule, RouterModule, NgxDatatableModule, AysCommonsModule],
    exports: [
        NavCustomComponent,
        PreloaderComponent,
        NotFoundComponent,
        NewUrlNoticeComponent,
        //LoginComponent,
        UnauthorizedComponent,
        // ErrorComponent,
    ],
    declarations: [
        NavCustomComponent,
        PreloaderComponent,
        NotFoundComponent,
        NewUrlNoticeComponent,
        //LoginComponent,
        UnauthorizedComponent,
        // ErrorComponent,
    ],
    providers: [environment.mode !== 'front' ? MsalService : [], SettingsService, ArchivesService, NavigationService],
})
export class CoreModule {}
