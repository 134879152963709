import { Component, EventEmitter, Input, OnDestroy, Output, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-timer',
    templateUrl: './timer.component.html',
})
export class TimerComponent implements OnChanges, OnDestroy {
    @Output() onComplete = new EventEmitter<void>();
    @Input() init: number;
    @Input() stopTimer: boolean;
    @Input() startTimer: boolean;

    countdownTimerRef: any = null;
    countdown: number = 0;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.startTimer?.currentValue) {
            this.startCountdown();
        }
        if (changes.stopTimer?.currentValue) {
            this.clearTimeout();
        }
    }

    startCountdown() {
        if (this.startTimer && this.init > 0) {
            this.clearTimeout();
            this.countdown = this.init;
            this.doCountdown();
        }
    }

    processCountdown() {
        if (this.countdown === 0) {
            this.onComplete.emit();
        } else {
            this.doCountdown();
        }
    }

    doCountdown() {
        this.countdownTimerRef = setTimeout(() => {
            this.countdown--;
            this.processCountdown();
        }, 1000);
    }

    clearTimeout() {
        if (this.countdownTimerRef) {
            clearTimeout(this.countdownTimerRef);
            this.countdownTimerRef = null;
        }
    }

    ngOnDestroy() {
        this.clearTimeout();
    }
}
