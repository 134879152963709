<app-svg-patterns></app-svg-patterns>
<core-preloader *ngIf="!pageNaked"></core-preloader>
<core-nav
    #nav
    [currentUser]="currentUser"
    [isDisconnected]="isDisconnected"
    [isReconnecting]="isReconnecting"
    (fullscreenChange)="saveFullscreen($event)"
    application="AUWA"
    *ngIf="!pageNaked"
>
    <core-nav-custom #customNav [parent]="nav" *ngIf="auwaStart"></core-nav-custom>
</core-nav>
<!-- <core-nav2 *ngIf="!pageNaked" [isDisconnected]="isDisconnected"></core-nav2> -->
<core-error></core-error>

<core-new-url-notice></core-new-url-notice>

<div class="main-wrapper">
    <div class="container-fluid">
        <router-outlet></router-outlet>
    </div>
</div>

<shared-lines-data-modal modalId="offcanvasEquipments" [lines]="lines" [equipments]="equipments"></shared-lines-data-modal>

<offcanvas modalId="offcanvasArchives" title="My archives" position="end">
    <div class="col-12 py-4">
        <ngx-datatable
            #table
            class="bootstrap"
            [rows]="itemsVariable"
            [limit]="10"
            [columnMode]="'flex'"
            [headerHeight]="50"
            [messages]="messages"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [sorts]="[{ prop: 'reportGenerationTimeStamp', dir: 'desc' }]"
        >
            <ngx-datatable-column name="reportGenerationTimeStamp" [sortable]="true" [flexGrow]="2">
                <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                    <span (click)="sort()">Date</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span class="force-one-line">{{ value | date: 'd/M H:mm' }}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="type" [flexGrow]="1.5">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <span>Type</span>
                </ng-template>
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span [innerHTML]="row | archiveType"></span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="filters" [flexGrow]="4">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <span>Filters</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <span class="force-one-line" [innerHTML]="row | displayFilter" [title]="row | displayFilter"></span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="from" [flexGrow]="1.5">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <span>From</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span class="force-one-line">{{ value | date: 'd/M/yy' }}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="to" [flexGrow]="1.5">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <span>To</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span class="force-one-line">{{ value | date: 'd/M/yy' }}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="reportSize" [flexGrow]="1.5">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <span>Size</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span [innerHTML]="value === 0 ? '-' : value + ' KB'"></span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="reportDownloadLink" [flexGrow]="0.5" cellClass="text-end">
                <ng-template ngx-datatable-header-template></ng-template>
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span [innerHTML]="row | downloadResourceIcon | async"></span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template
                    ngx-datatable-footer-template
                    let-rowCount="rowCount"
                    let-pageSize="pageSize"
                    let-selectedCount="selectedCount"
                    let-curPage="curPage"
                    let-offset="offset"
                >
                    <div class="col-12 col-lg-12 px-0">
                        <datatable-pager
                            [pagerLeftArrowIcon]="'datatable-icon-left'"
                            [pagerRightArrowIcon]="'datatable-icon-right'"
                            [pagerPreviousIcon]="'datatable-icon-prev'"
                            [pagerNextIcon]="'datatable-icon-skip'"
                            [page]="curPage"
                            [size]="pageSize"
                            [count]="rowCount"
                            [hidden]="!(rowCount / pageSize > 1)"
                            (change)="table.onFooterPage($event)"
                        >
                        </datatable-pager>
                    </div>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
</offcanvas>

<app-modal-centered #helpModal key="helpModal" [icon]="'icon-help'" title="Help" [extraSize]="'xxl'" [hideFooter]="'true'">
    <div class="row">
        <h5>Status colors</h5>
        <div class="col-md-6 col-12 border py-2">
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Base</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="box" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Box</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="warning" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Warning</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="warning box" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Warning + Box</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="disconnected" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Disconnected</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="run" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Run</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="run box" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Run + Box</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="run warning" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Run + Warning</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="run warning box" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Run + Warning + Box</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="run disconnected" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Run + Disconnected</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="lwdo" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">LWDO</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="lwdo box" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">LWDO + Box</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="lwdo warning" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">LWDO + Warning</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="lwdo warning box" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">LWDO + Warning + Box</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="lwdo disconnected" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">LWDO + Disconnected</div>
            </div>
            <!-- <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="box" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Box</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="box warning" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Box + Warning</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="box disconnected" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Box + Disconnected</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="box warning disconnected" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Box + Warning + Disconnected</div>
            </div> -->
        </div>
        <div class="col-md-6 col-12 border py-2">
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="alert" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Alert</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="alert box" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Alert + Box</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="alert warning" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Alert + Warning</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="alert warning box" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Alert + Warning + Box</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="alert disconnected" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Alert + Disconnected</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="critical" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Critical</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="critical box" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Critical + Box</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="critical warning" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Critical + Warning</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="critical warning box" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Critical + Warning + Box</div>
            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <svg viewBox="0 0 90 30" xmlns="http://www.w3.org/2000/svg" class="color-status p-2">
                        <path class="critical disconnected" d="M 0 0 H 100 V 100 H 0 L 0 0"></path>
                    </svg>
                </div>
                <div class="col-10 p-2">Critical + Disconnected</div>
            </div>
        </div>
    </div>
</app-modal-centered>
