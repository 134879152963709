import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ModalCenteredComponent } from '@ays/commons/lib/components/modal-centered/modal-centered.component';

const OLD_URL = 'https://auwa.inditex.com';
const NEW_URL = 'https://auwa.cloud.inditex.com';

@Component({
    selector: 'core-new-url-notice',
    templateUrl: './new-url-notice.component.html',
    styleUrls: ['./new-url-notice.component.scss'],
})
export class NewUrlNoticeComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('newUrlNoticeModal', { static: false }) newUrlNoticeModal: ModalCenteredComponent;
    oldUrl = OLD_URL;
    newUrl = NEW_URL;
    startTimer = false;
    stopTimer = false;
    private ngUnsubscribe: Subject<any> = new Subject();

    ngOnInit() {
        setTimeout(() => {
            this.checkUrl();
        }, 0);
    }

    ngAfterViewInit() {
        $('#newUrlNoticeModal > div > div > div.modal-footer').css('border-top', 'none');
        $('.fa-times').css('color', '#95a6a7');
        $('.modal-header').css('background-color', '#1a1a1a');
        this.newUrlNoticeModal.afterClose.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
            if (data.type === 'hidden') {
                this.stopTimer = true;
            }
        });
    }

    checkUrl() {
        const currentUrl = window.location.href;
        if (!currentUrl.includes('.cloud.')) this.checkDates();
    }

    checkDates() {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const lastShownTimestamp = JSON.parse(localStorage.getItem('lastShownUrlNotice'));
        let lastShown = null;
        if (lastShownTimestamp) {
            lastShown = new Date(lastShownTimestamp);
            lastShown.setHours(0, 0, 0, 0);
        }
        if (!lastShownTimestamp || currentDate.getTime() > lastShown.getTime()) {
            this.newUrlNoticeModal.openModal();
            this.startTimer = true;
            localStorage.setItem('lastShownUrlNotice', JSON.stringify(currentDate.getTime()));
        }
    }

    onComplete() {
        this.newUrlNoticeModal.closeModal();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }
}
